import request from '@/request'
export const queryNetDiskFile = (data) => {
    return request({
        url:`/api-gateway/service/queryNetDiskFile`,
        method:'post',
        data,
    });
}
export const saveNetDiskFile = (data) => {
    return request({
        url:'/api-gateway/service/saveNetDiskFile',
        method:'post',
        data,
    });
}
export const deleteNetDiskFile = (data) => {
    return request({
        url:'/api-gateway/service/deleteNetDiskFile',
        method:'post',
        data,
    });
}
export const uniqueName = (data) => {
    return request({
        url:'/api-gateway/service/uniqueNameNetDiskFile',
        method:'post',
        data,
    });
}
export const summaryNetDiskFile = () => {
    return request({
        url:'/api-gateway/service/summaryNetDiskFile',
        method:'post',
    });
}
export const getNetDiskFile = (data) => {
    return request({
        url:`/api-gateway/service/getNetDiskFile`,
        method:'post',
        data,
    });
}
export const shareFile = (data) => {
    return request({
        url:`/api-gateway/service/shareFile`,
        method:'post',
        data,
    });
}
export const queryShareList = (data) => {
    return request({
        url:`/api-gateway/service/queryShareList`,
        method:'post',
        data,
    });
}
export const deleteShareFile = (data) => {
    return request({
        url:`/api-gateway/service/deleteShareFile`,
        method:'post',
        data,
    });
}