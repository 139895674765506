import request from '@/request'
export const profile = (clientNo) => {
    return request({
        url:`/api-gateway/service/clientProfile/${clientNo}`,
        method:'get'
    });
}
export const queryClient = (data) => {
    return request({
        url:`/api-gateway/service/queryClient/${data.pageSize}/${data.pageNum}`,
        method:'post',
        data,
    });
}
export const saveClient = (data) => {
    return request({
        url:'/api-gateway/service/saveClient',
        method:'post',
        data,
    });
}
export const deleteClient = (data) => {
    return request({
        url:'/api-gateway/service/deleteClient',
        method:'post',
        data,
    });
}
export const uniqueName = (data) => {
    return request({
        url:'/api-gateway/service/uniqueNameClient',
        method:'post',
        data,
    });
}
export const queryContact = (data) => {
    return request({
        url:`/api-gateway/service/queryContact`,
        method:'post',
        data,
    });
}
export const saveContact = (data) => {
    return request({
        url:'/api-gateway/service/saveContact',
        method:'post',
        data,
    });
}
export const deleteContact = (data) => {
    return request({
        url:'/api-gateway/service/deleteContact',
        method:'post',
        data,
    });
}