import request from '@/request'
export const getTemplate = (code) => {
    return request({
        url:`/api-gateway/service/emailTemplate/${code}`,
        method:'get'
    });
}

export const sendEmail = (data) => {
    return request({
        url:'/api-gateway/service/sendEmail',
        method:'post',
        data,
    });
}