<script>
import Swal from "sweetalert2";
import Vue from "vue";
import {queryClient} from '@/apis/client'
import {relateClient,createClientbyOrder} from '@/apis/order'
import {
  required
} from "vuelidate/lib/validators";
export default {
    props:{
        data:Object,
        show: {
            type:Boolean,
            default:false
        }
    },
    validations: {
    params: {
      filter: { 
            clientName:{ 
            required,
      },
      },
    },
  },
    data(){
        return {
            clientId:null,
            submitted:false,
            clientList:[],
            params:{
                pageNum:1,
                pageSize:10,
                filter:{
                    clientName:""
                }
            }
        }
    },
    components: {},
    computed:{
        orderData(){
            if(this.data==null||this.data==undefined){
                return {orderNo:"",clientName:""}
            }else{
                return this.data
            }
        }
    },
    watch:{
        show(newValue){
            if(newValue){
                this.$refs.relateClientModal.show()
            }else{
                this.$refs.relateClientModal.hide()
            }
        }
    },
    created(){
        this.keyword = this.orderData.clientName
    },
    methods:{
        loadData(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            } else {
                queryClient(this.params).then((res)=>{
                    this.clientList = res.data
                })
            }
        },
        handleCreateClient(){
            Swal.fire({
                title: this.$t("order.infoSure"),
                text: this.$t("order.infoCreateClient"),
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("actions.ok"),
                cancelButtonText: this.$t("actions.cancel")
            }).then(result => {
                if (result.value) {
                    createClientbyOrder(this.data.orderId).then((res)=>{
                        this.clientId = res.data
                        this.$refs.relateClientModal.hide()
                    }).catch(()=>{
                        Vue.swal(this.$t("order.errorCreateFail"));
                    })
                }
            });

        },
        handleRelateClient(clientId){
            Swal.fire({
                title: this.$t("order.infoSure"),
                text: this.$t("order.infoBindOrder"),
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("actions.ok"),
                cancelButtonText: this.$t("actions.cancel")
            }).then(result => {
                if (result.value) {
                    relateClient({orderId:this.data.orderId,clientId:clientId}).then(()=>{
                        this.clientId = clientId
                        this.$refs.relateClientModal.hide()
                    }).catch(()=>{
                        Vue.swal(this.$t("order.errorBindingFail"));
                    })
                }
            });
        },
        handleClose(){
            this.$emit('close', this.clientId)
        },
    }
};
</script>
<template>
    <b-modal
    id="modal-center"
    ref="relateClientModal"
    centered
    :title='$t("actions.bindingClient")'
    title-class="font-18"
    hide-footer
    @hidden="handleClose"
    >
    <p><strong>{{orderData.orderNo}} - {{ orderData.clientName }}</strong></p>
    <div class="row mb-3">
        <div class="col-lg-6 col-sm-6">
            <div class="search-box mb-2 me-2">
                <div class="position-relative">
                    <input type="search" v-model="params.filter.clientName" class="form-control bg-light border-light rounded" :placeholder='$t("client.name")'
                    :class="{'is-invalid': submitted && $v.params.filter.clientName.$error,
                                    }">
                    <div
                        v-if="submitted && $v.params.filter.clientName.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.params.filter.clientName.required">
                                {{$t("order.infoEnterClientName")}}.
                            </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-sm-6">
        <div class="d-flex flex-wrap mt-2 mt-md-0 gap-2 mb-3">
                <div>
                    <ul class="nav nav-pills">
                        <li class="nav-item">
                            <b-button variant="primary"  @click="loadData">{{$t("actions.search")}}</b-button>
                        </li>                                  
                    </ul>
                </div>                                               
            </div>                        
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-sm-12" v-if="clientList.length==0 && submitted">
            <div class="card">
                <div class="card-body p-4">
                    <div class="d-flex align-items-start">
                        <div class="flex-grow-1 overflow-hidden">
                            <h5 class="font-size-15 mb-1 text-center">{{$t("order.infoNoRecord")}}</h5>                                           
                        </div>
                    </div>
                    <div class="pt-2 text-center">
                        <button type="button" class="btn btn-primary btn-sm text-center ms-2"   @click="handleCreateClient"><i class="typcn typcn-document-text me-1 align-middle"></i> {{$t("actions.newClient")}}</button>
                    </div>
                </div>
                <!-- end card body -->
            </div><!-- end card -->
        </div><!-- end col -->        
        <div class="col-xl-6 col-sm-6" v-for="row in clientList" :key="row.clientNo">
            <div class="card">
                <div class="card-body p-4">
                    <div class="d-flex align-items-start">
                        <div class="flex-grow-1 overflow-hidden">
                            <h5 class="font-size-15 mb-1 text-truncate">{{row.clientName}}</h5>                                           
                        </div>
                    </div>
                    <p class="mt-4 text-muted">
                           {{row.address}}</p>
                    <div class="pt-2">
                        <button type="button" class="btn btn-primary btn-sm text-truncate ms-2"  @click="handleRelateClient(row.clientId)"><i class="typcn typcn-document-text me-1 align-middle"></i> {{$t("actions.binding")}}</button>
                    </div>
                </div>
                <!-- end card body -->
            </div><!-- end card -->
        </div><!-- end col -->
    </div><!-- end row -->
    </b-modal>
</template>
