import request from '@/request'
export const orderDetail = (orderNo) => {
    return request({
        url:`/api-gateway/service/orderDetail/${orderNo}`,
        method:'get'
    });
}
export const queryOrder = (data) => {
    return request({
        url:`/api-gateway/service/queryOrder/${data.pageSize}/${data.pageNum}`,
        method:'post',
        data,
    });
}
export const saveOrder = (data) => {
    return request({
        url:'/api-gateway/service/saveOrder',
        method:'post',
        data,
    });
}
export const relateClient = (data) => {
    return request({
        url:`/api-gateway/service/relateClient`,
        method:'post',
        data,
    });
}
export const createClientbyOrder = (data) => {
    return request({
        url:'/api-gateway/service/createClientbyOrder',
        method:'post',
        data,
    });
}
export const deleteOrder = (orderNo) => {
    return request({
        url:`/api-gateway/service/deleteOrder/${orderNo}`,
        method:'post',
    });
}
export const createQuotationFile = (orderNo) => {
    return request({
        url:`/api-gateway/service/createQuotationFile/${orderNo}`,
        method:'post',
    });
}
export const setOrderSales = (data) => {
    return request({
        url:'/api-gateway/service/setOrderSales',
        method:'post',
        data,
    });
}
export const setOrderStatus = (data) => {
    return request({
        url:'/api-gateway/service/setOrderStatus',
        method:'post',
        data,
    });
}
export const queryQuotation = (data) => {
    return request({
        url:`/api-gateway/service/queryQuotation`,
        method:'post',
        data,
    });
}
export const saveQuotation = (data) => {
    return request({
        url:'/api-gateway/service/saveQuotation',
        method:'post',
        data,
    });
}
export const deleteQuotation = (data) => {
    return request({
        url:'/api-gateway/service/deleteQuotation',
        method:'post',
        data,
    });
}
export const createContract = (data,languageCode) => {
    let paramsData = {...data}
    paramsData.country = languageCode
    return request({
        url:'/api-gateway/service/createContract',
        method:'post',
        data:paramsData,
    });
}
export const createOrderInvoice = (data) => {
    return request({
        url:'/api-gateway/service/createOrderInvoice',
        method:'post',
        data,
    });
}
export const updateOrderFile = (data) => {
    return request({
        url:'/api-gateway/service/updateOrderFile',
        method:'post',
        data,
    });
}

export const invoiceDetail = (orderNo,clientId) => {
    return request({
        url:`/api-gateway/service/invoiceDetail/${orderNo}/${clientId}`,
        method:'get'
    });
}
export const saveInvoice = (data) => {
    return request({
        url:'/api-gateway/service/saveInvoice',
        method:'post',
        data,
    });
}
export const invoiceEmail = (orderNo) => {
    return request({
        url:`/api-gateway/service/invoiceEmail/${orderNo}`,
        method:'get'
    });
}
export const setInvoiceStatus = (orderNo) => {
    return request({
        url:`/api-gateway/service/setInvoiceStatus/${orderNo}`,
        method:'get'
    });
}
export const createInvoiceFile = (orderNo,clientId) => {
    return request({
        url:`/api-gateway/service/createInvoiceFile/${orderNo}/${clientId}`,
        method:'get'
    });
}
export const createApplicationForm = (data) => {
    return request({
        url:'/api-gateway/service/createApplicationForm',
        method:'post',
        data,
    });
}
