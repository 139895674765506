import request from '@/request'
export const uploadFile = (data) => {
    return request({
        url:`/api-gateway/service/upload`,
        method:'post',
        headers:{
            'Content-Type':'multipart/form-data'
        },
        data,
    });
};
export const deleteFile = (data) => {
    return request({
        url:`/api-gateway/service/deleteFile`,
        method:'get',
        params:data
    });
};
